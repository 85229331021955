import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { useAppSelector } from "../redux/hook";
import { formatPhoneNumber } from "../utils/lib";

import "react-phone-input-2/lib/bootstrap.css";
import { useDispatch } from "react-redux";
import { message } from "antd";
import _ from "lodash";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountRegisterInput } from "../graphql/types";
import { AccountRegisterDocument } from "./__generated__/accountRegister";
import { useMutation } from "@apollo/client";
import { notification } from "antd";
import { RequestOtpDocument } from "./__generated__/requestOTP";
import { VerifyOtpDocument } from "./__generated__/verifyOTP";
import { UpdateUserDetailsDocument } from "./__generated__/updateUserDetails";
import { RESELLER_COUNTRY, RESELLER_TOKEN } from "../utils/constants";
import { setUser } from "../redux/slice/auth.slice";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { UserLoginDocument } from "./__generated__/userLogin";

const RegisterForm = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountRegisterInput>();
  const location = useLocation();

  const refLink = new URLSearchParams(location.search);
  const refCode = refLink?.get("refCode");

  const [phone, setPhone] = useState("");
  const [token, setToken] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [password, setPassword] = useState("");
  const [dob, setDob] = useState("");
  const [isMale, setIsMale] = useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [otp, setOtp] = useState("");
  const [referredBy, setReferredBy] = useState(refCode ?? "");
  const [hidePassword, setHidePassword] = useState(false);
  const [addDetailsInput, setAddDetailsInput] = useState(false);

  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  // const init = useRef({
  //   navigate,
  // });

  const [registerAccount, { loading: isLoadingRegisterAccount }] = useMutation(
    AccountRegisterDocument,
    {
      onError(error) {
        notification.error({
          message: "Failed to register user",
          description: error.message,
        });
      },
      onCompleted() {
        notification.success({
          message: "Success!",
          description: "User registered successfully",
        });
        notification.success({
          message: "We've send an OTP code to verify your account",
        });
      },
    }
  );
  const [userLogin] = useMutation(UserLoginDocument, {
    variables: {
      input: {
        password,
        phone,
      },
    },
    onError(error) {
      console.log("error, login user");
    },
    onCompleted() {
      console.log("login success");
    },
  });
  const [updateUserDetails, { loading: isLoadingUpdateUserDetails }] =
    useMutation(UpdateUserDetailsDocument, {
      onError(error) {
        notification.error({
          message: "Failed to update user details",
          description: error.message,
        });
      },
      onCompleted() {
        notification.success({
          message: "Success!",
          description: "User details updated successfully",
        });
        navigate("/auth/onboard");
      },
    });

  const [verifyOTP, { loading: isVerifyingOTP }] = useMutation(
    VerifyOtpDocument,
    {
      variables: {
        input: {
          code: otp,
          medium: "phone",
          token,
        },
      },
      onError(error) {
        notification.error({
          message: "Failed to verify code",
          description: error.message,
        });
      },
      onCompleted() {
        notification.success({
          message: "Success",
          description: "Code has been verified successfully",
        });
      },
    }
  );
  const [requestOTP, { loading: isRequestingOTP }] = useMutation(
    RequestOtpDocument,
    {
      variables: {
        input: {
          phone,
        },
      },
      onError(error) {
        notification.error({
          message: "Failed to send OTP code",
          description: error.message,
        });
      },
      onCompleted() {
        notification.success({
          message: "Success",
          description:
            "A code has been sent to your phone as SMS. Input the code below",
        });
      },
    }
  );

  async function handleVerifyOTP() {
    const { data } = await verifyOTP();
    if (data && data.verifyOTP) {
      setShowOTPInput(false);
      setAddDetailsInput(true);
    }
  }

  async function handleRequestOTP() {
    const { data } = await requestOTP();
    if (data && data.requestOTP) {
      const { token } = data.requestOTP;
      setToken(String(token));
    }
  }

  const onSignUpSubmit = async (data: AccountRegisterInput) => {
    data.country = "GH";
    data.password = password;
    data.phone = phone;

    if (_.isEmpty(data))
      return message.error("Form fields can not be empty", 5);

    if (["", undefined, null].includes(data?.phone)) {
      message.error("Phone number can not be empty", 5);
      return;
    }

    if (!showOTPInput && !addDetailsInput) {
      const { data: registerAccountData } = await registerAccount({
        variables: {
          input: {
            ...data,
          },
        },
      });
      if (registerAccountData && registerAccountData.accountRegister) {
        const { token, user } = registerAccountData.accountRegister;
        //! @Evans use token and user here
        localStorage.setItem(RESELLER_TOKEN, token);
        localStorage.setItem(RESELLER_COUNTRY, String(user?.country));
        setToken(String(token));
        dispatch(setUser(user));
        setShowOTPInput(true);
      }
    } else {
      //!@Evans
      await updateUserDetails({
        variables: {
          input: {
            user_id: Number(user?.id),
            dob,
            gender: isMale ? "Male" : "Female",
            referred_by: referredBy,
          },
        },
      });
      const { data } = await userLogin();
      if (data && data.userLogin) {
        localStorage.setItem(RESELLER_TOKEN, data?.userLogin?.token || "");
        dispatch(setUser({ ...data?.userLogin?.user }));
      }
    }
  };

  const showPassword = () => {
    setHidePassword(!hidePassword);
  };

  return (
    <div className="">
      {showOTPInput ? (
        <div className="">
          <div className="text-left mb-4">
            <h1 className="text-3xl text-center">Verify your account 😎</h1>
          </div>
          <div className="mb-6">
            <span className="text-sm">
              We have sent you a code to phone number as SMS, enter it below to
              continue.
            </span>
          </div>
          <div className="mb-2">
            <label htmlFor="OTP" className="block text-sm font-medium">
              Enter the 4 digit code you received on phone here.
            </label>
            <div className="my-2 relative rounded-md shadow-sm">
              <input
                required
                type="text"
                id="OTP"
                className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
            <div className="flex items-center">
              <span className="text-xs font-semibold mr-2 mt-1">
                Did not receive code?
              </span>
              <div
                className="text-blue-500 mt-1 text-xs font-semibold cursor-pointer"
                onClick={handleRequestOTP}
              >
                {isRequestingOTP ? "Please wait..." : "Resend code"}
              </div>
            </div>

            <div className="my-5 w-full">
              <button
                type="button"
                className={`w-full flex justify-center py-4 px-4 border border-transparent text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 text-white ${
                  isVerifyingOTP || otp.length === 0
                    ? "cursor-not-allowed bg-gray-300"
                    : "bg-blue-500"
                }`}
                disabled={isVerifyingOTP || otp.length === 0}
                onClick={handleVerifyOTP}
              >
                {isVerifyingOTP && (
                  <AiOutlineLoading3Quarters className="h-6 w-6 animate-spin text-blue-400 mr-2" />
                )}
                Next
              </button>
            </div>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSignUpSubmit)}>
          {!addDetailsInput && (
            <>
              <div className="text-left mb-4">
                <h1 className="text-3xl text-center">Create your account 👌🏾</h1>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="block text-sm font-medium">
                  Your full name
                </label>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <input
                    type="text"
                    id="name"
                    autoComplete="name"
                    className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
                    placeholder="John Doe"
                    {...register("name", { required: true })}
                  />
                </div>
                {errors.name?.type === "required" && (
                  <span
                    className={`block text-xs text-red-600 mt-2 font-semibold`}
                  >
                    Please enter your full legal name
                  </span>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="phone" className="block text-sm font-medium">
                  Your phone number
                </label>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <PhoneInput
                    country={"gh"}
                    onlyCountries={["gh"]}
                    enableSearch={true}
                    inputStyle={{ width: "100%" }}
                    inputClass="focus:ring-gray-300 focus:border-gray-300 sm:text-sm border-gray-300 border-2 py-4"
                    placeholder="+233 20xxxxxx"
                    value={phone}
                    countryCodeEditable={false}
                    onChange={(value) => {
                      formatPhoneNumber({
                        value,
                        cb: setPhone,
                        errorCb: setPhoneError,
                      });
                    }}
                  />
                </div>
                {phoneError && (
                  <span className="text-red-500 mt-1 text-xs font-semibold">
                    Invalid phone number
                  </span>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="block text-sm font-medium">
                  Your email
                </label>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <input
                    type="email"
                    id="email"
                    autoComplete="email"
                    className="focus:ring-gray-300 focus:border-gray-300 block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
                    placeholder="example@mail.com"
                    {...register("email", { required: true })}
                  />
                </div>
                {errors.email?.type === "required" && (
                  <span
                    className={`block text-xs text-red-600 mt-2 font-semibold`}
                  >
                    Please enter your email
                  </span>
                )}
              </div>

              <div className="mt-6">
                <label htmlFor="password" className="block text-sm font-medium">
                  Your password
                </label>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <input
                    type={hidePassword ? "text" : "password"}
                    id="password"
                    className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
                    placeholder="******"
                    {...register("password", { required: true })}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div
                    className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    onClick={showPassword}
                  >
                    {hidePassword ? (
                      <EyeOffIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <EyeIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                </div>
                {errors.password?.type === "required" && (
                  <span
                    className={`block text-xs text-red-600 mt-2 font-semibold`}
                  >
                    Please enter a password
                  </span>
                )}
              </div>
            </>
          )}

          {addDetailsInput && (
            <>
              <div className="text-left mb-4">
                <h1 className="text-3xl text-center">
                  Complete your details 😉
                </h1>
              </div>
              <div className="mb-3 mt-2">
                <label htmlFor="email" className="block text-sm font-medium">
                  Your gender
                </label>
                <div className="mt-1 flex space-x-4">
                  <div className="flex items-center">
                    <input
                      id="male"
                      name="gender"
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      onChange={() => setIsMale(true)}
                    />
                    <label
                      htmlFor="male"
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      Male
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="female"
                      name="gender"
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      onChange={() => setIsMale(false)}
                    />
                    <label
                      htmlFor="female"
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      Female
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="block text-sm font-medium">
                  Your date of birth
                </label>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <input
                    type="date"
                    id="dob"
                    autoComplete="dob"
                    className="focus:ring-gray-300 focus:border-gray-300 block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
                    placeholder="DD/MM/YYYY"
                    onChange={(e) => {
                      setDob(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="block text-sm font-medium">
                  Who referred you? (Optional)
                </label>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <input
                    type="text"
                    id="referralCode"
                    className="focus:ring-gray-300 focus:border-gray-300 block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2  rounded-md"
                    placeholder="Enter referral code"
                    defaultValue={refCode || ""}
                    onChange={(e) => setReferredBy(e.target.value)}
                  />
                </div>
                <span className={`block text-xs mt-2 font-semibold`}>
                  Enter the referral code of the one who referred you. If none,
                  enter TENDO.
                </span>
              </div>{" "}
            </>
          )}
          <div className="flex flex-col py-4">
            <div className="my-2 w-full">
              <button
                type="submit"
                className={`w-full flex justify-center py-4 px-4 border border-transparent text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 text-white ${
                  isLoadingRegisterAccount || isLoadingUpdateUserDetails
                    ? "cursor-not-allowed bg-gray-200"
                    : "bg-blue-500"
                }`}
                disabled={
                  isLoadingRegisterAccount || isLoadingUpdateUserDetails
                    ? true
                    : false
                }
              >
                {(isLoadingRegisterAccount || isLoadingUpdateUserDetails) && (
                  <AiOutlineLoading3Quarters className="h-6 w-6 animate-spin text-blue-400 mr-2" />
                )}
                {addDetailsInput ? "Continue" : "Create Account"}
              </button>
            </div>
            <p className="text-sm text-center font-semibold text-gray-400 mt-3">
              Already a reseller?{" "}
              <span
                className="ml-1 text-blue-500 cursor-pointer"
                onClick={() => {
                  navigate("/auth");
                }}
              >
                Sign in here
              </span>
            </p>
          </div>
          <p className="text-center text-sm text-gray-400 mt-2">
            By clicking on create an account <br />
            you have agreed to our{" "}
            <a
              href="https://rebrand.ly/tendo-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-1 text-blue-500"
            >
              Terms &amp; Conditions
            </a>
          </p>
        </form>
      )}
    </div>
  );
};

export default RegisterForm;
