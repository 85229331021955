import { useQuery } from "@apollo/client";
import {
  GetProductsDocument,
  GetProductsLengthDocument,
  GetProductsLengthQuery,
  GetProductsLengthQueryVariables,
  GetProductsQuery,
  GetProductsQueryVariables,
} from "../graphql/queries/__generated__/products.query";

const useGetProducts = (variables: GetProductsQueryVariables) => {
  const { data, loading, error, fetchMore, networkStatus } = useQuery<
    GetProductsQuery,
    GetProductsQueryVariables
  >(GetProductsDocument, {
    fetchPolicy: "cache-first",
    variables,
  });

  const {
    data: result,
    loading: _loading,
    error: _error,
  } = useQuery<GetProductsLengthQuery, GetProductsLengthQueryVariables>(
    GetProductsLengthDocument,
    {
      variables,
    }
  );

  const products = data?.getProducts ?? [];
  const productsCount = !_loading && !_error ? result?.getProductsLength : 0;
  return { products, productsCount, loading, error, fetchMore, networkStatus };
};

export default useGetProducts;
