import { call, put, select, SelectEffect } from "redux-saga/effects";
import sheetService from "../../services/sheet.service";
import {
  ADD_CUSTOMER_TO_CART,
  ADD_DELIVERY_TO_CART,
  ADD_PRODUCT_TO_CART,
  ADD_RESELLER_TO_CART,
  CHECKOUT_CART,
  CLEAR_CART,
  EDIT_CART_ITEM,
  GET_COUPON,
  GET_CUSTOMERS,
  REMOVE_PRODUCT_FROM_CART,
} from "../../utils/constants";
import { ICart } from "../../utils/types";
import {
  addCustomerToCart,
  addDeliveryToCart,
  addProductToCart,
  addResellerToCart,
  clearCart,
  editCartProduct,
  removeProductFromCart,
  setCheckingOut,
  setCheckoutStatus,
  setCoupon,
  setNavigate,
  setResellerCustomers,
} from "../slice/cart.slice";
import { RootState, store } from "../store";

function* initRequest() {
  yield put(setCheckingOut(true));
  yield put(setCheckoutStatus(null));
}

function* handleError(error: any) {
  const obj: Record<string, any> = {};
  obj.success = false;
  obj.error = error.message || "Something went wrong";
  obj.isError = true;

  yield put(setCheckingOut(false));
  yield put(setCheckoutStatus(obj));
}

function selectState<T>(selector: (s: RootState) => T): SelectEffect {
  return select(selector);
}

const selector = (state: RootState): ReturnType<typeof store.getState> => state;

function* cartSaga({ type, payload }: { type: string; payload: any }) {
  switch (type) {
    case ADD_PRODUCT_TO_CART:
      yield put(addProductToCart(payload));
      break;
    case REMOVE_PRODUCT_FROM_CART:
      yield put(removeProductFromCart(payload));
      break;
    case ADD_CUSTOMER_TO_CART:
      yield put(addCustomerToCart(payload));
      break;
    case ADD_DELIVERY_TO_CART:
      yield put(addDeliveryToCart(payload));
      break;
    case ADD_RESELLER_TO_CART:
      yield put(addResellerToCart(payload));
      break;
    case CHECKOUT_CART:
      yield initRequest();
      const state: ReturnType<typeof selector> = yield selectState(
        selector
      ) as unknown as ReturnType<typeof selector>;
      const { cart } = state;
      try {
        const { cart: orderCart } = cart;

        const data: Record<string, any> = yield call(
          sheetService.checkoutCart,
          orderCart as ICart
        );

        if (data?.success === true) {
          yield put(setCheckingOut(false));
          yield put(clearCart());
          yield put(setNavigate(true));
        }
      } catch (error) {
        console.log(error);
        yield handleError(error);
      }
      break;
    case EDIT_CART_ITEM:
      yield put(editCartProduct(payload));
      break;
    case CLEAR_CART:
      yield put(clearCart());
      break;
    case GET_CUSTOMERS:
      yield initRequest();
      try {
        const results: Record<string, any> = yield call(
          sheetService.getUserCustomers,
          payload
        );
        if (results?.success === true) {
          yield put(setResellerCustomers(results.data));
          yield put(setCheckingOut(false));
        }
      } catch (error) {
        console.log(error);
        yield handleError(error);
      }
      break;
    case GET_COUPON:
      // yield initRequest();
      try {
        const state: ReturnType<typeof selector> = yield selectState(
          selector
        ) as unknown as ReturnType<typeof selector>;
        const reseller = state.auth.user;
        const results: Record<string, any> = yield call(
          sheetService.getCouponCode,
          payload,
          reseller?.username
        );

        if (results?.success === true) {
          yield put(setCheckingOut(false));
          yield put(setCoupon(results.data));
        }
      } catch (error) {
        console.log(error);
        yield handleError(error);
      }
      break;
    default:
      yield console.log("cartSaga", type, payload);
      break;
  }
}

export default cartSaga;
