import React from "react";
import { AiOutlineLoading } from "react-icons/ai";
import ScreenWrapper from "./ScreenWrapper";

interface Props {}

const Loader = (props: Props) => {
  return (
    <ScreenWrapper>
      <div className="flex justify-center items-center min-h-full w-full">
        <AiOutlineLoading className="h-6 w-6 animate-spin text-gray-800" />
      </div>
    </ScreenWrapper>
  );
};

export default Loader;
