import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ICart,
  ICartProduct,
  ICustomer,
  IDelivery,
  IReseller,
} from "../../utils/types";
import dayjs from "dayjs";

interface ICartState {
  cart: ICart | null;
  active: boolean;
  createdOn: string;
  orderedOn: string;
  promptUser: boolean;
  checkingOut: boolean;
  checkoutStatus: Record<string, any> | null;
  previousFee: number;
  resellerCustomers: Record<string, any>[];
  coupon: Record<string, any> | null;
  navigate: boolean;
}

const initialState: ICartState = {
  cart: null,
  active: false,
  createdOn: "",
  orderedOn: "",
  promptUser: false,
  checkingOut: false,
  checkoutStatus: null,
  previousFee: 0,
  resellerCustomers: [],
  coupon: null,
  navigate: false,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addProductToCart: (state, action: PayloadAction<ICartProduct>) => {
      const { cart } = state;
      const product = action.payload;

      // check if cart is not 1 day old
      const createdOn = dayjs(state.createdOn);
      const now = dayjs();
      const diff = now.diff(createdOn, "day");
      if (diff > 1) {
        state.promptUser = true;
      }

      if (cart) {
        const products = cart.products;
        const productIndex = products.findIndex(
          (p) => p.productSKU === product.productSKU
        );

        if (productIndex > -1) {
          products[productIndex].productQty += product.productQty;
        } else {
          products.push(product);
        }

        cart.products = products;
        cart.totalAmountToCollect =
          products.reduce((acc, p) => {
            return (
              acc +
              Number(p.productPrice.replace(",", "")) * p.productQty +
              Number(p.profitAdded)
            );
          }, 0) + (cart.delivery ? cart.delivery.rate : 0);
        cart.totalResellerProfit = products.reduce((acc, p) => {
          return acc + Number(p.profitAdded);
        }, 0);
      } else {
        state.cart = {
          products: [action.payload],
          status: "PENDING",
          totalAmountToCollect:
            Number(product.productPrice.replace(",", "")) *
              Number(product.productQty) +
            Number(product.profitAdded),
          totalResellerProfit: Number(product.profitAdded),
        };
        state.active = true;
        state.createdOn = dayjs().format("YYYY-MM-DD");
      }
    },
    removeProductFromCart: (state, action: PayloadAction<ICartProduct>) => {
      const { cart } = state;
      if (cart) {
        const products = cart.products;
        const productIndex = products.findIndex(
          (p) => p.productSKU === action.payload.productSKU
        );
        if (productIndex > -1) {
          products.splice(productIndex, 1);
        }
        cart.products = products;
        cart.totalAmountToCollect = products.reduce((acc, p) => {
          return (
            acc +
            Number(p.productPrice) * Number(p.productQty) +
            Number(p.profitAdded)
          );
        }, 0);
        cart.totalResellerProfit = products.reduce((acc, p) => {
          return acc + Number(p.profitAdded);
        }, 0);
      }
    },
    addDeliveryToCart: (state, action: PayloadAction<IDelivery>) => {
      const { cart } = state;
      const snapshot = state;

      if (cart) {
        cart.delivery = action.payload;
        cart.totalAmountToCollect =
          cart.totalAmountToCollect + cart.delivery.rate;

        cart.totalAmountToCollect =
          cart.totalAmountToCollect - snapshot.previousFee;
      }
      state.previousFee = action.payload.rate;
    },
    addCustomerToCart: (state, action: PayloadAction<ICustomer>) => {
      const { cart } = state;
      if (cart) {
        cart.customer = action.payload;
      }
    },
    addResellerToCart: (state, action: PayloadAction<IReseller>) => {
      const { cart } = state;
      if (cart) {
        cart.reseller = action.payload;
      }
    },

    setCouponCode: (state, action: PayloadAction<string>) => {
      const { cart, coupon } = state;
      if (cart) {
        cart.couponCode = action.payload;
        cart.couponAmount =
          coupon && coupon?.couponData
            ? Number(coupon.couponData.promoAmt)
            : undefined;
      }
    },

    clearCart: (state) => {
      state.cart = null;
      state.active = false;
      state.createdOn = "";
      state.orderedOn = "";
      state.promptUser = false;
      state.previousFee = 0;
      state.coupon = null;
      state.checkoutStatus = null;
    },
    setCheckingOut: (state, action: PayloadAction<boolean>) => {
      state.checkingOut = action.payload;
    },
    setCheckoutStatus: (
      state,
      action: PayloadAction<Record<string, any> | null>
    ) => {
      state.checkoutStatus = action.payload;
    },
    editCartProduct: (state, action: PayloadAction<ICartProduct>) => {
      const { cart } = state;
      if (cart) {
        const products = cart.products;
        const productIndex = products.findIndex(
          (p) => p.productSKU === action.payload.productSKU
        );
        if (productIndex > -1) {
          products[productIndex] = action.payload;
        }
        cart.products = products;
        cart.totalAmountToCollect =
          products.reduce((acc, p) => {
            return (
              acc +
              Number(p.productPrice.replace(",", "")) * Number(p.productQty) +
              Number(p.profitAdded)
            );
          }, 0) + (cart.delivery ? cart.delivery.rate : 0);
        cart.totalResellerProfit = products.reduce((acc, p) => {
          return acc + Number(p.profitAdded);
        }, 0);
      }
    },
    setResellerCustomers: (
      state,
      action: PayloadAction<Record<string, any>[]>
    ) => {
      state.resellerCustomers = action.payload;
    },
    setCoupon: (state, action: PayloadAction<Record<string, any> | null>) => {
      state.coupon = action.payload;
    },
    setNavigate: (state, action: PayloadAction<boolean>) => {
      state.navigate = action.payload;
    },
    setUsePrompt: (state, action: PayloadAction<boolean>) => {
      state.promptUser = action.payload;
    },
    setCartPayment: (state, action: PayloadAction<Record<string, any>>) => {
      const { cart } = state;
      const { payload } = action;
      if (cart) {
        cart.paymentId = payload?.reference;
        cart.paymentMethod = payload?.method;
        cart.paymentStatus = payload?.status;
        cart.deliveryPaymentStatus = payload?.deliveryPaymentStatus ?? "Paid";
      }
    },
  },
});

export const {
  addProductToCart,
  removeProductFromCart,
  addCustomerToCart,
  addDeliveryToCart,
  addResellerToCart,
  clearCart,
  setCheckingOut,
  setCheckoutStatus,
  editCartProduct,
  setResellerCustomers,
  setCoupon,
  setCouponCode,
  setNavigate,
  setUsePrompt,
  setCartPayment,
} = cartSlice.actions;

export default cartSlice.reducer;
