import { InformationCircleIcon } from "@heroicons/react/solid";
import { message } from "antd";
import React, { useState } from "react";
import { HiOutlineMinusCircle, HiOutlinePlusCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { toggleCartModal } from "../redux/actions/appActions";
import { addProductToCart } from "../redux/actions/cartActions";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { ICartProduct } from "../utils/types";

type Props = {};

const AddProductToCartForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const { orderProduct } = useAppSelector((state) => state.orders);
  const [moreProfit, setMoreProfit] = useState(false);
  const [typedSpec, setTypedSpec] = useState(
    orderProduct ? orderProduct.combination_string?.replace("&", "-") : ""
  );
  const [profit, setProfit] = useState(0);
  const [qty, setQty] = useState(1);

  const navigate = useNavigate();

  const ChangeQty = (e: any) => {
    setQty(+e.target.value);
  };

  const inc = () => {
    setQty(qty + 1);
  };
  const dec = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };

  const addToCart = () => {
    const productObj: ICartProduct = {
      imageLink: String(orderProduct?.avatar),
      name: String(orderProduct?.title),
      productPrice: String(orderProduct?.price),
      productQty: qty,
      productSKU: String(orderProduct?.sku),
      profitAdded: profit.toString(),
      spec: String(typedSpec),
    };
    dispatch(addProductToCart(productObj));
    message.success("Product added to cart", 5);
    dispatch(toggleCartModal(false));
  };

  const orderNow = () => {
    const productObj: ICartProduct = {
      imageLink: String(orderProduct?.avatar),
      name: String(orderProduct?.title),
      productPrice: String(orderProduct?.price),
      productQty: qty,
      productSKU: String(orderProduct?.sku),
      profitAdded: profit.toString(),
      spec: String(typedSpec),
      supplierPrice: orderProduct?.cost_price,
    };
    dispatch(addProductToCart(productObj));
    message.success("Product added to cart", 5);
    dispatch(toggleCartModal(false));
    navigate("/cart");
  };

  return (
    <div className="flex">
      <div className="w-full flex flex-col flex-1 overflow-y-scroll">
        <div className="flex flex-col w-full">
          <div className="flex-1 p-2 bg-gray-100 my-2 rounded-md">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5">
                <img
                  className="h-16 w-16 rounded-md"
                  src={orderProduct?.avatar}
                  alt=""
                />
              </div>
              <div className="ml-3 w-0 flex-1 flex flex-col">
                <span className="text-xs font-medium text-gray-500">
                  {orderProduct?.title}
                </span>
                <span className="mt-1 text-sm font-bold text-gray-900">
                  GHS {orderProduct?.price}
                </span>
                <span className="mt-1 text-sm font-medium text-gray-500">
                  {orderProduct?.sku}
                </span>
              </div>
            </div>
          </div>

          <div className="rounded-md bg-blue-50 p-2">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <span className="text-sm text-blue-700">
                  You have added GHS{" "}
                  {isNaN(profit)
                    ? 0.0
                    : parseFloat(profit.toString()).toFixed(2)}{" "}
                  profit to {qty} quantity
                </span>
              </div>
            </div>
          </div>

          <div className="mb-2 mt-4">
            <label htmlFor="profit" className="block text-sm font-medium">
              Enter your profit
            </label>
            <div className="relative rounded-md shadow-sm">
              <input
                type="number"
                name="profit"
                id="profit"
                required
                className="focus:ring-gray-300 focus:border-gray-300 block w-full pl-7 pr-12 py-2 sm:text-sm border-gray-300 border-2 rounded-md"
                value={profit}
                onChange={(e) => {
                  setProfit(e.target.valueAsNumber);
                  if (
                    e.target.valueAsNumber >
                    (orderProduct ? orderProduct?.price * qty : 0)
                  ) {
                    setMoreProfit(true);
                  } else {
                    setMoreProfit(false);
                  }
                }}
                step="any"
                placeholder="0.0"
              />
            </div>
            {moreProfit && (
              <span className={`text-xs font-medium text-yellow-500 mt-1`}>
                We advice you add profit up to 100% of product price.
              </span>
            )}
          </div>

          <div className="my-2">
            <label htmlFor="profit" className="block text-sm font-medium">
              Enter quantity
            </label>
            <div className="relative rounded-md py-1 flex-1">
              <input
                type="number"
                id="productQty"
                name="productQty"
                value={qty}
                className="focus:ring-tendo-active focus:border-tendo-active block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md relative"
                onChange={(e) => ChangeQty(e)}
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                <HiOutlinePlusCircle
                  className="h-4 w-4 mx-1 cursor-pointer"
                  onClick={(e) => inc()}
                />

                <HiOutlineMinusCircle
                  onClick={(e) => dec()}
                  className="h-4 w-4 mx-1 cursor-pointer"
                />
              </div>
            </div>
          </div>

          {orderProduct?.combination_string && (
            <div className="mb-3">
              <label htmlFor="spec" className="block text-sm font-medium">
                Product spec
              </label>
              <div className="mt-2 relative rounded-md shadow-sm">
                <input
                  type="text"
                  id="spec"
                  autoComplete="spec"
                  className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-2 sm:text-sm border-gray-300 border-2 rounded-md placeholder:text-gray-200 disabled:bg-gray-200"
                  placeholder="Black small"
                  value={typedSpec}
                  onChange={(e) => {
                    setTypedSpec(e.target.value);
                  }}
                  disabled
                />
              </div>
            </div>
          )}

          <div className="flex space-x-3 my-2">
            <div className="flex w-full">
              <button
                type="button"
                className={`w-full flex justify-center py-4 px-4 text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 border-2 border-blue-500 text-blue-500 hover:bg-blue-600 hover:text-white`}
                onClick={addToCart}
              >
                Add to cart
              </button>
            </div>
            <div className="flex w-full">
              <button
                type="button"
                className={`w-full flex justify-center py-4 px-4 border border-transparent text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${"bg-blue-500 text-white"}`}
                onClick={orderNow}
              >
                Order now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductToCartForm;
