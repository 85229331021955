import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RESELLER_COUNTRY, RESELLER_TOKEN } from "./constants";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API_GATEWAY,
  fetch,
});

const authLink = setContext((_, { headers }) => {
  //TODO!@Evans ->
  //!implement these functions or use if they already exist
  const token = localStorage.getItem(RESELLER_TOKEN);
  const country = localStorage.getItem(RESELLER_COUNTRY);

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // Cookies: JSON.stringify(cookies),
      authorization: token ? `Bearer ${token}` : "",
      country: country ? country : "GH",
      source: "WEB",
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, response, operation, forward }) => {
    if (graphQLErrors)
      for (const err of graphQLErrors) {
        const { message, locations, path } = err;
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );

        switch (message) {
          case "Unauthorized":
            if (typeof window !== "undefined") {
              console.info("Your session has expired. Please sign in");

              operation.setContext(({ headers }: any) => {
                //TODO!remove expired cookie and bearer token
                return {
                  headers: {
                    ...headers,
                    Cookies: "",
                    authorization: "",
                  },
                };
              });

              return forward(operation);
            }
        }
      }

    if (networkError) console.error(`[Network error]: ${networkError}`);
  }
);

const links = [errorLink, authLink, httpLink];
const link = ApolloLink.from(links);

export const apiGatewayClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  credentials: "include",
  connectToDevTools: process.env.NODE_ENV === "production" ? false : true,
  name:
    process.env.NODE_ENV === "production" ? "" : "Tendo-Web-GH Apollo Client",
});
