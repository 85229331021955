export const GET_HOME_PRODUCTS = "GET_HOME_PRODUCTS";
export const GET_EXPLORE_PRODUCTS = "GET_EXPLORE_PRODUCTS";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_PROMOTIONS = "GET_PROMOTIONS";
export const PRODUCT_FETCH_ERROR = "PRODUCT_FETCH_ERROR";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_CATEGORY_PRODUCTS = "GET_CATEGORY_PRODUCTS";
export const SET_CATEGORY_NAME = "SET_CATEGORY_NAME";
export const GET_PROMO_PRODUCTS = "GET_PROMO_PRODUCTS";
export const SET_PROMO_NAME = "SET_PROMO_NAME";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const LOGIN_USER = "LOGIN_USER";
export const REGISTER_USER = "REGISTER_USER";
export const LOGOUT = "LOGOUT";
export const SET_IS_FETCHING = "SET_IS_FETCHING";
export const TOGGLE_LOGIN = "TOGGLE_LOGIN";
export const TOGGLE_REGISTER = "TOGGLE_REGISTER";
export const SET_SIGNOUT_MODAL = "SET_SIGNOUT_MODAL";
export const ORDER_PRODUCT = "ORDER_PRODUCT";
export const DELIVERY_DATA = "DELIVERY_DATA";
export const ORDERING = "ORDERING";
export const GET_ALL_USER_ORDERS = "GET_ALL_USER_ORDERS";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const GET_USER_WALLET_DATA = "GET_USER_WALLET_DATA";
export const DELETE_PENDING_TRANSACTION = "DELETE_PENDING_TRANSACTION";
export const SET_UPDATE_PAYMENT_MODAL = "SET_UPDATE_PAYMENT_MODAL";
export const SET_UPDATE_PROFILE_MODAL = "SET_UPDATE_PROFILE_MODAL";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PAYMENT = "UPDATE_USER_PAYMENT";
export const REQUEST_PAYOUT = "REQUEST_PAYOUT";
export const CLOSE_ERROR_MODAL = "CLOSE_ERROR_MODAL";
export const SHARE_PRODUCT = "SHARE_PRODUCT";
export const GET_USER_SHARED_PRODUCTS = "GET_USER_SHARED_PRODUCTS";
export const DOWNLOAD_PRODUCT_IMAGE = "DOWNLOAD_PRODUCT_IMAGE";
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const DELETE_SHARED_PRODUCT = "DELETE_SHARED_PRODUCT";
export const ADD_PRODUCT_TO_CART = "ADD_PRODUCT_TO_CART";
export const REMOVE_PRODUCT_FROM_CART = "REMOVE_PRODUCT_FROM_CART";
export const ADD_CUSTOMER_TO_CART = "ADD_CUSTOMER_TO_CART";
export const ADD_DELIVERY_TO_CART = "ADD_DELIVERY_TO_CART";
export const ADD_RESELLER_TO_CART = "ADD_RESELLER_TO_CART";
export const CHECKOUT_CART = "CHECKOUT_CART";
export const CLEAR_CART = "CLEAR_CART";
export const TOGGLE_CART_MODAL = "TOGGLE_CART_MODAL";
export const EDIT_CART_ITEM = "EDIT_CART_ITEM";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_COUPON = "GET_COUPON";
export const RESELLER_TOKEN = "reseller-token";
export const RESELLER_COUNTRY = "reseller-country";
