import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../redux/hook";
import { formatPhoneNumber } from "../utils/lib";

import "react-phone-input-2/lib/bootstrap.css";

import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CheckPasswordAvailabilityDocument } from "./__generated__/checkPasswordAvailability";
import { notification } from "antd";
import { UserLoginDocument } from "./__generated__/userLogin";
import { setUser } from "../redux/slice/auth.slice";
import { RESELLER_COUNTRY, RESELLER_TOKEN } from "../utils/constants";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { RequestOtpDocument } from "./__generated__/requestOTP";

interface Props {}

const LoginForm = (props: Props) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showPasswordInput, setShowPassword] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [hidePassword, setHidePassword] = useState(false);
  const { previousPage } = useAppSelector((state) => state.app);
  const navigate = useNavigate();

  //!check password availability
  const [checkPasswordAvailability, { loading: passwordAvailabilityLoading }] =
    useMutation(CheckPasswordAvailabilityDocument, {
      variables: {
        input: {
          phone,
        },
      },
      onError(error) {
        notification.error({
          message: "Failed attempt to login",
          description: error.message,
        });
      },
    });
  const [userLogin] = useMutation(UserLoginDocument, {
    variables: {
      input: {
        password,
        phone,
      },
    },
    onError(error) {
      notification.error({
        message: "Failed attempt to login",
        description: error.message,
      });
    },
    onCompleted() {
      notification.success({
        message: "Success",
        description: "Login successful",
      });
    },
  });
  const [requestOTP] = useMutation(RequestOtpDocument, {
    variables: {
      input: {
        phone,
      },
    },
    onError(error) {
      notification.error({
        message: "Failed to send OTP code",
        description: error.message,
      });
    },
    onCompleted() {
      notification.success({
        message: "Success",
        description:
          "A code has been sent to your phone as SMS. Input the code below",
      });
    },
  });

  // const init = useRef({
  //   navigate,
  // });

  const isLoading = React.useMemo(
    () => passwordAvailabilityLoading,
    [passwordAvailabilityLoading]
  );

  //!old login
  // const onLoginSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   isAuthenticated && message.loading("Loading...", 0);
  //   dispatch(loginUser({ phone }));
  // };

  //!new login
  async function handleLogin(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const { data } = await checkPasswordAvailability();
    if (!data) return;

    const passwordIsAvailable = data?.checkPasswordAvailability;
    //!check if password is available
    if (passwordIsAvailable) {
      setShowPassword(true);
    }

    if (passwordIsAvailable && !["", undefined, null].includes(password)) {
      const userLoginResponse = await userLogin();
      if (userLoginResponse && userLoginResponse.data) {
        const { userLogin } = userLoginResponse.data;

        localStorage.setItem(RESELLER_TOKEN, userLogin?.token || "");
        localStorage.setItem(RESELLER_COUNTRY, userLogin?.user?.country || "");
        dispatch(setUser({ ...userLogin?.user }));
        if (previousPage) {
          navigate(previousPage);
        } else {
          navigate("/");
        }
      }
    }

    //!handle when password is not available
    if (!passwordIsAvailable) {
      notification.info({
        message: "Password reset",
        description:
          "Due to a security upgrade of your account, provide a new password",
        duration: 10,
      });
      const { data } = await requestOTP();
      navigate("/auth/reset-password", {
        state: { phone, token: data ? data.requestOTP.token : null },
      });
    }
  }

  const showPassword = () => {
    setHidePassword(!hidePassword);
  };

  return (
    <form onSubmit={handleLogin} className="w-full">
      <div className="text-left mb-9">
        <h1 className="text-3xl text-center">Welcome back ✌🏽</h1>
      </div>
      <div className="mb-5">
        <label htmlFor="phone" className="block text-sm font-medium">
          Your phone number
        </label>
        <div className="mt-2 relative rounded-md shadow-sm">
          <PhoneInput
            country={"gh"}
            enableSearch={true}
            onlyCountries={["gh"]}
            countryCodeEditable={false}
            inputStyle={{ width: "100%" }}
            inputClass="focus:ring-sokoBlue focus:border-sokoBlue sm:text-sm border-gray-300 py-4"
            placeholder="+233 20xxxxxx"
            value={phone}
            onChange={(value) => {
              formatPhoneNumber({
                value,
                cb: setPhone,
                errorCb: setPhoneError,
              });
            }}
          />
        </div>
        {phoneError && (
          <span className="text-red-500 mt-1 text-xs font-semibold">
            Invalid phone number
          </span>
        )}
      </div>
      {showPasswordInput && (
        <div className="mt-6">
          <label htmlFor="password" className="block text-sm font-medium">
            Your password
          </label>
          <div className="mt-2 relative rounded-md shadow-sm">
            <input
              type={hidePassword ? "text" : "password"}
              id="password"
              className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={showPassword}
            >
              {hidePassword ? (
                <EyeOffIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              )}
            </div>
          </div>
          <span
            className="text-blue-500 mt-1 text-xs font-semibold cursor-pointer"
            onClick={async () => {
              const { data } = await requestOTP();

              navigate(
                {
                  pathname: "/auth/reset-password",
                  search: `?forgotten_password=true`,
                },
                { state: { token: data ? data.requestOTP.token : null, phone } }
              );
            }}
          >
            Forgotten Password? Reset my password
          </span>
        </div>
      )}

      <div className="flex flex-col">
        <div className="my-5 w-full">
          <button
            type="submit"
            className={`w-full flex justify-center py-4 px-4 border border-transparent text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 text-white ${
              isLoading ? "cursor-not-allowed bg-gray-300" : "bg-blue-500"
            }`}
            disabled={isLoading}
          >
            {isLoading && (
              <AiOutlineLoading3Quarters className="h-6 w-6 animate-spin text-blue-400 mr-2" />
            )}{" "}
            Login
          </button>
        </div>
        <p className="text-sm text-center font-semibold text-gray-400 mt-3">
          Not a Reseller yet?{" "}
          <span
            className="ml-1 text-blue-500 cursor-pointer"
            onClick={() => {
              navigate("/auth/register");
            }}
          >
            Create account here
          </span>
        </p>
      </div>
    </form>
  );
};

export default LoginForm;
