import {
  AUTHENTICATE_USER,
  LOGIN_USER,
  LOGOUT,
  REGISTER_USER,
  UPDATE_USER_PAYMENT,
  UPDATE_USER_PROFILE,
} from "../../utils/constants";

export const authenticateUser = (payload: string) => ({
  type: AUTHENTICATE_USER,
  payload,
});

export const loginUser = (payload: Record<string, any>) => ({
  type: LOGIN_USER,
  payload,
});

export const registerUser = (payload: Record<string, any>) => ({
  type: REGISTER_USER,
  payload,
});

export const logoutUser = (payload: boolean) => ({
  type: LOGOUT,
});

export const setUpdateUserDetails = (payload: Record<string, any>) => ({
  type: UPDATE_USER_PROFILE,
  payload,
});

export const setPaymentMethod = (payload: Record<string, any>) => ({
  type: UPDATE_USER_PAYMENT,
  payload,
});
