import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  isAuthenticated: boolean;
  user: Record<string, any> | null;
  authStatus: Record<string, any> | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  authStatus: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setUser: (state, action: PayloadAction<Record<string, any> | null>) => {
      state.user = action.payload;
    },
    setAuthStatus: (
      state,
      action: PayloadAction<Record<string, any> | null>
    ) => {
      state.authStatus = action.payload;
    },
  },
});

export const { setUser, setIsAuthenticated, setAuthStatus } = authSlice.actions;

export default authSlice.reducer;
