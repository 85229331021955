import { Select } from "antd";
import React, { useEffect, useMemo } from "react";
import { Product } from "../graphql/types";
import useGetProducts from "../hooks/useGetProducts";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { setSearchProduct } from "../redux/slice/product.slice";
import SearchItem from "./SearchItem";

interface Props {
  data: Product[];
}

const Search = ({ data }: Props) => {
  const { Option } = Select;
  const dispatch = useAppDispatch();
  const { searchProduct } = useAppSelector((state) => state.products);
  const { products, loading } = useGetProducts({
    filter: {
      random: true,
    },
    pagination: {
      limit: 200,
      offset: data.length,
    },
  });

  useEffect(() => {
    dispatch(setSearchProduct(data));
  }, [data, dispatch]);

  const options = useMemo(() => {
    return searchProduct.map((item) => (
      <Option key={item.sku} value={item.id}>
        <SearchItem item={item} />
      </Option>
    ));
  }, [Option, searchProduct]);

  function onChange(value: any) {
    console.log(`selected`);
  }

  function onSearch(val: string) {
    // console.log("search:", val);
    if (val.length >= 3) {
      if (searchProduct.length !== 0) {
        const filteredData = searchProduct.filter((item) => {
          return (
            item.title.toLowerCase().includes(val.toLowerCase()) ||
            item?.sku?.toLowerCase().includes(val.toLowerCase()) ||
            item?.tags?.includes(val.toLowerCase())
          );
        });
        if (filteredData.length !== 0) {
          dispatch(setSearchProduct(filteredData));
        } else {
          const moreFilterData = products.filter((item) => {
            return (
              item?.title.toLowerCase().includes(val.toLowerCase()) ||
              item?.sku?.toLowerCase().includes(val.toLowerCase()) ||
              item?.tags?.includes(val.toLowerCase())
            );
          });
          dispatch(setSearchProduct(moreFilterData as Product[]));
        }
      } else {
        dispatch(setSearchProduct(data));
      }
    } else {
      dispatch(setSearchProduct(data));
    }
  }

  return (
    <Select
      showSearch
      placeholder="Search product name, sku or tag"
      optionFilterProp="children"
      onChange={onChange}
      onSearch={onSearch}
      filterOption={false}
      className="w-full search-bar"
      loading={loading}
    >
      {options}
    </Select>
  );
};

export default Search;
